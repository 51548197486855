nav{
  position: fixed;
  top:0;
  background: #414b53;
  /* height: 120px; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  padding-bottom: 5px;
  width: 100%;
  z-index: 1;
  border-bottom: 2px solid #555;
  box-shadow: 0 2px 10px rgba(0,0,0,.5);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 40' width='90' height='45'%3E%3Cpath fill='%238b783a' fill-opacity='0.1' d='M0 40a19.96 19.96 0 0 1 5.9-14.11 20.17 20.17 0 0 1 19.44-5.2A20 20 0 0 1 20.2 40H0zM65.32.75A20.02 20.02 0 0 1 40.8 25.26 20.02 20.02 0 0 1 65.32.76zM.07 0h20.1l-.08.07A20.02 20.02 0 0 1 .75 5.25 20.08 20.08 0 0 1 .07 0zm1.94 40h2.53l4.26-4.24v-9.78A17.96 17.96 0 0 0 2 40zm5.38 0h9.8a17.98 17.98 0 0 0 6.67-16.42L7.4 40zm3.43-15.42v9.17l11.62-11.59c-3.97-.5-8.08.3-11.62 2.42zm32.86-.78A18 18 0 0 0 63.85 3.63L43.68 23.8zm7.2-19.17v9.15L62.43 2.22c-3.96-.5-8.05.3-11.57 2.4zm-3.49 2.72c-4.1 4.1-5.81 9.69-5.13 15.03l6.61-6.6V6.02c-.51.41-1 .85-1.48 1.33zM17.18 0H7.42L3.64 3.78A18 18 0 0 0 17.18 0zM2.08 0c-.01.8.04 1.58.14 2.37L4.59 0H2.07z'%3E%3C/path%3E%3C/svg%3E");
}
a{
  display: block;
  max-width: 160px;
  margin: 10px 0 10px 10px;
  width:30%;
}
.pokedex{
  width: 100%;
}
.pokedex:hover{
  filter: brightness(110%);
}


.loading-container p{
  text-align: left;
  margin: 15px;
  color: white;
}

.loading-container p strong{
  text-transform: capitalize;
}
.loading-bar{
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  background: rgb(116, 201, 204);
}

.nav-container{
  box-sizing: border-box;
  margin-top: 5px ;
  width: 90%;
  max-width: 340px;
  display: flex;
  align-items: center;
  justify-content: center;
} 
input{
  height:35px;
  width: 80%;
  background: #2c2c2c;
  border: 1px solid #777;
  color: #ccc;
  border-radius: 5px;
  padding-left: 10px;
  font-size: 1.2rem;
  font-family: "PT Sans Narrow",sans-serif;  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-shadow:inset 0 0 10px #00000099;
  
}
input:focus{
  background: #222;
  outline: none;
  border: 1px solid rgb(92, 174, 189);
}
input::placeholder{
  text-align: center;
  font-size: 1rem;
}

select{
  height: 25px;
  margin-right: 10px;
  background: #333;
  border: 1px solid #777;
  color: #ccc;
  border-radius: 5px;
  padding:0 10px;
  font-size: 1rem;
  font-family: "PT Sans Narrow",sans-serif;  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* box-shadow:inset 0 0 10px #00000099; */
}

select:focus{
  background: #333;
  outline: none;
  border: 1px solid rgb(92, 174, 189);
}
