.loader {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
}
.loader img {
  position: absolute;
  margin-top: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 96px;
  width: 10%;
  animation: spin 1s linear infinite;
  filter: drop-shadow(0 0 10px rgba(0, 39, 58, 0.3));
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
