.card-border{
  box-sizing: border-box;
  padding: 7px;
  background: #776e39;
  border: 2px solid transparent;
  background: linear-gradient(70deg,#ccc, #333, #ccc);
  border-radius: 15px;
  transition:  0.25s ease-out;
  box-shadow: 0 2px  10px 0px  rgba(0, 0, 0, 0.6);
}

.card-container{
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: flex-start;
  background: #20282e;
  border-radius: 10px;
  padding:5px 15px;
  height: 360px;
  color:#eee;

  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 4 4'%3E%3Cpath fill='%23596772' fill-opacity='0.2' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
}
.card-border:hover{
  transform: scale(1.01);
  border:2px solid rgb(151, 246, 255);
  box-shadow: 0 10px  20px 0px  rgba(0, 0, 0, 0.3);
}

.title{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 5px;
 
}
.name{
  margin: 0;
  color: #eee;
  font-size: 1.3rem;
  font-weight: bold;
  text-transform: capitalize;
}
.id{
  font-weight: normal;
  font-size: .8rem;
  color:#ccc;
}
.hp{
  font-size: 1.2rem;
  color: rgb(255, 224, 138);
}


.image-container{
  background: radial-gradient( hsl(201, 20%, 28%),#172b35);
  min-height: 180px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid rgb(243, 232, 133);
  box-shadow: 0 0 15px rgb(0, 0, 0,.7);
  overflow: hidden;
}

.image-container img{
  max-width:65%;
  margin-bottom: -25px;
  max-height: 150px;
  filter: drop-shadow(0px 0px 25px rgba(255, 217, 167, 0.5));
}
.bkg0{
  background: url(./img/fall_compressed.jpg);
  background-size: cover;
}
.bkg1{
  background: url(./img/forest_compressed.jpg);
  background-size: cover;
}
.bkg2{
  background: url(./img/field_compressed.jpg);
  background-size: cover;
}
.bkg3{
  background: url(./img/night_compressed.jpg);
  background-size: cover;
}
.bkg4{
  background: url(./img/grass_compressed.jpg);
  background-size: cover;
}
.bkg5{
  background: url(./img/fall_compressed.jpg);
  background-size: cover;
}
 

.abilities{
  margin-top:5px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  
}
.ability{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin: 0;
  position: relative;
}

.ability:nth-child(2),.ability:nth-child(3){
  border-top: 1px solid #ffffff22;
}
.ability p{
  margin: 5px 10px;
  padding: 0;
  font-size: .8rem;
  font-weight: bold;
  text-transform: capitalize;
  color:#00000000;
  white-space: nowrap;
  background-clip: text;
}

.ability span{
  color:#ccc;
  text-align: center;
  font-size: 0.8rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 5px;
}
.ability span label{
  cursor: pointer;
}
.ability span input{
  display: none;
}
.abilities input:checked ~ .tooltip{
  display:block;
}
.ability span .tooltip{
  content:attr(title);
  position: absolute;
  font-size: 1rem;
  line-height: 1rem;
  color: #ddd;
  background: #333;
  padding: 3px 6px;
  border-radius: 5px;
  top: 50%;
  left:50%;
  padding: 10px;
  transform: translate(-50%,-60px);
  white-space: normal;
  text-align: center;
  width: 100%;
  z-index: 2;
  display: none;
}



.stats{
  font-size: .8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  height: 25px;
}
.stats p {
  margin-right: 3px;
  color:#ccc;
  text-align: center;
}
.stats strong{
  text-transform: capitalize;
  color: rgb(185, 241, 255);
  font-weight: normal;
}
.types{
  color: #111;
  border-radius: 5px;
  margin-right:5px;
  padding: 0 5px;
  background:#aaa;
}

.normal{
  background: #aaa;
}
.grass{
  background: #67ec7d;
}
.poison{
  background: #89d31a;
}
.fire{
  background: rgb(253, 136, 41);
}
.water{
  background: rgb(57, 205, 250);
}
.electric{
  background: rgb(250, 237, 57);
}
.ground{
  background: rgb(138, 135, 90);
}
.fairy{
  background: rgb(241, 131, 255);
}
.bug{
  background: rgb(181, 243, 123);
}
.flying{
  background: rgb(209, 209, 209);
}
.psychic{
  background: rgb(173, 101, 255);
}
.fighting{
  background: rgb(255, 133, 133);
}
.rock{
  background: rgb(155, 122, 71);
}
.steel{
  background: rgb(101, 149, 143);
}
.ice{
  background: rgb(156, 255, 250);
}
.dragon{
  background: rgb(255, 195, 125);
}
.ghost{
  background: rgb(214, 214, 214);
}
.dark{
  background: rgb(107, 103, 117);
}


