@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Narrow:wght@400;700&display=swap');

body {
  margin: 0;
  background: #333;
  font-family: "PT Sans Narrow",sans-serif;  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("data:image/svg+xml,%3Csvg width='26' height='13' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%238b783a' fill-opacity='0.1'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

}

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  background: #222;
}


::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 15px;
  border: 3px solid transparent;
  background-clip: content-box;
}


::-webkit-scrollbar-thumb:hover {
  background: #666;
  border: 3px solid transparent;
  background-clip: content-box;
}