@import url(https://fonts.googleapis.com/css2?family=PT+Sans+Narrow:wght@400;700&display=swap);
body {
  margin: 0;
  background: #333;
  font-family: "PT Sans Narrow",sans-serif;  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("data:image/svg+xml,%3Csvg width='26' height='13' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%238b783a' fill-opacity='0.1'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

}

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  background: #222;
}


::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 15px;
  border: 3px solid transparent;
  background-clip: content-box;
}


::-webkit-scrollbar-thumb:hover {
  background: #666;
  border: 3px solid transparent;
  background-clip: content-box;
}
.App{
}
footer{
  border-top: 1px solid #666;
  color: #ccc;
  background: #333;
  text-align: center;
  padding: 5px 10px;
  font-size: .8rem;
}
footer a{
  display: inline-block;
  margin:0;
  color: #ccc;
  text-decoration: none;
}
footer a:hover{
  color:rgb(133, 208, 251);
}
.card-list{
    width: 90vW;
    margin: 0 auto;
    margin-top: 120px;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(240px,1fr));
    grid-gap:20px;
}
.card-border{
  box-sizing: border-box;
  padding: 7px;
  background: #776e39;
  border: 2px solid transparent;
  background: linear-gradient(70deg,#ccc, #333, #ccc);
  border-radius: 15px;
  transition:  0.25s ease-out;
  box-shadow: 0 2px  10px 0px  rgba(0, 0, 0, 0.6);
}

.card-container{
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  /* align-items: center; */
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  background: #20282e;
  border-radius: 10px;
  padding:5px 15px;
  height: 360px;
  color:#eee;

  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 4 4'%3E%3Cpath fill='%23596772' fill-opacity='0.2' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
}
.card-border:hover{
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
  border:2px solid rgb(151, 246, 255);
  box-shadow: 0 10px  20px 0px  rgba(0, 0, 0, 0.3);
}

.title{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  margin-bottom: 5px;
 
}
.name{
  margin: 0;
  color: #eee;
  font-size: 1.3rem;
  font-weight: bold;
  text-transform: capitalize;
}
.id{
  font-weight: normal;
  font-size: .8rem;
  color:#ccc;
}
.hp{
  font-size: 1.2rem;
  color: rgb(255, 224, 138);
}


.image-container{
  background: radial-gradient( hsl(201, 20%, 28%),#172b35);
  min-height: 180px;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-radius: 5px;
  border: 1px solid rgb(243, 232, 133);
  box-shadow: 0 0 15px rgb(0, 0, 0,.7);
  overflow: hidden;
}

.image-container img{
  max-width:65%;
  margin-bottom: -25px;
  max-height: 150px;
  -webkit-filter: drop-shadow(0px 0px 25px rgba(255, 217, 167, 0.5));
          filter: drop-shadow(0px 0px 25px rgba(255, 217, 167, 0.5));
}
.bkg0{
  background: url(../../static/media/fall_compressed.033009ac.jpg);
  background-size: cover;
}
.bkg1{
  background: url(../../static/media/forest_compressed.6f22c40d.jpg);
  background-size: cover;
}
.bkg2{
  background: url(../../static/media/field_compressed.2fe01424.jpg);
  background-size: cover;
}
.bkg3{
  background: url(../../static/media/night_compressed.12125343.jpg);
  background-size: cover;
}
.bkg4{
  background: url(../../static/media/grass_compressed.09adfa86.jpg);
  background-size: cover;
}
.bkg5{
  background: url(../../static/media/fall_compressed.033009ac.jpg);
  background-size: cover;
}
 

.abilities{
  margin-top:5px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  height: 100%;
  
}
.ability{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  height: 100%;
  margin: 0;
  position: relative;
}

.ability:nth-child(2),.ability:nth-child(3){
  border-top: 1px solid #ffffff22;
}
.ability p{
  margin: 5px 10px;
  padding: 0;
  font-size: .8rem;
  font-weight: bold;
  text-transform: capitalize;
  color:#00000000;
  white-space: nowrap;
  -webkit-background-clip: text;
          background-clip: text;
}

.ability span{
  color:#ccc;
  text-align: center;
  font-size: 0.8rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 5px;
}
.ability span label{
  cursor: pointer;
}
.ability span input{
  display: none;
}
.abilities input:checked ~ .tooltip{
  display:block;
}
.ability span .tooltip{
  content:attr(title);
  position: absolute;
  font-size: 1rem;
  line-height: 1rem;
  color: #ddd;
  background: #333;
  padding: 3px 6px;
  border-radius: 5px;
  top: 50%;
  left:50%;
  padding: 10px;
  -webkit-transform: translate(-50%,-60px);
          transform: translate(-50%,-60px);
  white-space: normal;
  text-align: center;
  width: 100%;
  z-index: 2;
  display: none;
}



.stats{
  font-size: .8rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 10px;
  height: 25px;
}
.stats p {
  margin-right: 3px;
  color:#ccc;
  text-align: center;
}
.stats strong{
  text-transform: capitalize;
  color: rgb(185, 241, 255);
  font-weight: normal;
}
.types{
  color: #111;
  border-radius: 5px;
  margin-right:5px;
  padding: 0 5px;
  background:#aaa;
}

.normal{
  background: #aaa;
}
.grass{
  background: #67ec7d;
}
.poison{
  background: #89d31a;
}
.fire{
  background: rgb(253, 136, 41);
}
.water{
  background: rgb(57, 205, 250);
}
.electric{
  background: rgb(250, 237, 57);
}
.ground{
  background: rgb(138, 135, 90);
}
.fairy{
  background: rgb(241, 131, 255);
}
.bug{
  background: rgb(181, 243, 123);
}
.flying{
  background: rgb(209, 209, 209);
}
.psychic{
  background: rgb(173, 101, 255);
}
.fighting{
  background: rgb(255, 133, 133);
}
.rock{
  background: rgb(155, 122, 71);
}
.steel{
  background: rgb(101, 149, 143);
}
.ice{
  background: rgb(156, 255, 250);
}
.dragon{
  background: rgb(255, 195, 125);
}
.ghost{
  background: rgb(214, 214, 214);
}
.dark{
  background: rgb(107, 103, 117);
}



nav{
  position: fixed;
  top:0;
  background: #414b53;
  /* height: 120px; */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  padding-bottom: 5px;
  width: 100%;
  z-index: 1;
  border-bottom: 2px solid #555;
  box-shadow: 0 2px 10px rgba(0,0,0,.5);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 40' width='90' height='45'%3E%3Cpath fill='%238b783a' fill-opacity='0.1' d='M0 40a19.96 19.96 0 0 1 5.9-14.11 20.17 20.17 0 0 1 19.44-5.2A20 20 0 0 1 20.2 40H0zM65.32.75A20.02 20.02 0 0 1 40.8 25.26 20.02 20.02 0 0 1 65.32.76zM.07 0h20.1l-.08.07A20.02 20.02 0 0 1 .75 5.25 20.08 20.08 0 0 1 .07 0zm1.94 40h2.53l4.26-4.24v-9.78A17.96 17.96 0 0 0 2 40zm5.38 0h9.8a17.98 17.98 0 0 0 6.67-16.42L7.4 40zm3.43-15.42v9.17l11.62-11.59c-3.97-.5-8.08.3-11.62 2.42zm32.86-.78A18 18 0 0 0 63.85 3.63L43.68 23.8zm7.2-19.17v9.15L62.43 2.22c-3.96-.5-8.05.3-11.57 2.4zm-3.49 2.72c-4.1 4.1-5.81 9.69-5.13 15.03l6.61-6.6V6.02c-.51.41-1 .85-1.48 1.33zM17.18 0H7.42L3.64 3.78A18 18 0 0 0 17.18 0zM2.08 0c-.01.8.04 1.58.14 2.37L4.59 0H2.07z'%3E%3C/path%3E%3C/svg%3E");
}
a{
  display: block;
  max-width: 160px;
  margin: 10px 0 10px 10px;
  width:30%;
}
.pokedex{
  width: 100%;
}
.pokedex:hover{
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
}


.loading-container p{
  text-align: left;
  margin: 15px;
  color: white;
}

.loading-container p strong{
  text-transform: capitalize;
}
.loading-bar{
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  background: rgb(116, 201, 204);
}

.nav-container{
  box-sizing: border-box;
  margin-top: 5px ;
  width: 90%;
  max-width: 340px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
} 
input{
  height:35px;
  width: 80%;
  background: #2c2c2c;
  border: 1px solid #777;
  color: #ccc;
  border-radius: 5px;
  padding-left: 10px;
  font-size: 1.2rem;
  font-family: "PT Sans Narrow",sans-serif;  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-shadow:inset 0 0 10px #00000099;
  
}
input:focus{
  background: #222;
  outline: none;
  border: 1px solid rgb(92, 174, 189);
}
input::-webkit-input-placeholder{
  text-align: center;
  font-size: 1rem;
}
input:-ms-input-placeholder{
  text-align: center;
  font-size: 1rem;
}
input::placeholder{
  text-align: center;
  font-size: 1rem;
}

select{
  height: 25px;
  margin-right: 10px;
  background: #333;
  border: 1px solid #777;
  color: #ccc;
  border-radius: 5px;
  padding:0 10px;
  font-size: 1rem;
  font-family: "PT Sans Narrow",sans-serif;  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* box-shadow:inset 0 0 10px #00000099; */
}

select:focus{
  background: #333;
  outline: none;
  border: 1px solid rgb(92, 174, 189);
}

.loader {
  height: 100vh;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  transition: 0.2s;
}
.loader img {
  position: absolute;
  margin-top: 40px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  max-width: 96px;
  width: 10%;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
  -webkit-filter: drop-shadow(0 0 10px rgba(0, 39, 58, 0.3));
          filter: drop-shadow(0 0 10px rgba(0, 39, 58, 0.3));
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
  }
}

