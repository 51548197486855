.App{
}
footer{
  border-top: 1px solid #666;
  color: #ccc;
  background: #333;
  text-align: center;
  padding: 5px 10px;
  font-size: .8rem;
}
footer a{
  display: inline-block;
  margin:0;
  color: #ccc;
  text-decoration: none;
}
footer a:hover{
  color:rgb(133, 208, 251);
}